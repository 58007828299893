@font-face {
  font-family: "HindSiliguri-Regular";
  src: url("./assets/font/Hind_Siliguri/HindSiliguri-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'HindSiliguri-Regular';
  background-color: #9E3040;
}

.hero-section {
  position: relative;
  color: white;
  overflow: hidden;
}

.phone-image {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  max-width: 90%;
  z-index: 1;
}

.hero-bg {
  position: relative;
  z-index: 2;
  background-image: url("./assets/img/hero-bg1.png");
  /* Replace with your background image path */
  background-size: contain;
  background-position: center;

  /* padding: 20px; Adjust padding as needed */
}

.phone-image {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  max-width: 90%;
  z-index: 1;
}

@media (max-width: 768px) {

  .hero-section {
    text-align: center;
  }

  .content {
    text-align: center;
  }
}


.text {
  margin-bottom: 2rem;
}

.tag {
  display: inline-block;
  background-color: #f9c74f;
  color: #ae3c57;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: bold;
}


h1 {
  font-size: 3rem;
  margin: 1rem 0;
}


.feature-heading {

  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

}


.feature-paragraph {

  font-size: 13px;
  font-weight: 400;
  line-height: 20.15px;
  text-align: center;
  color: #F4B410;

}

p {
  font-size: 1.2rem;
}

.explore-btn {
  background-color: #f94144;
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.explore-btn:hover {
  background-color: #d62828;
}

.features {
  display: flex;
  gap: 2rem;
}

.feature-item {
  text-align: center;
}


.phone-image img {
  max-width: 100%;
}

.image-gift {
  width: 70px;
  /* Set a specific width */
  height: 80px;
  /* Set the same height to make it a perfect circle */
  border-radius: 50%;
  /* Make it circular */
  background-color: #F26C7E;
  /* Background color */
  display: flex;
  /* Flexbox to center content */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  position: relative;
  /* Position relative to the parent container if needed */
}

.image-gift img {
  max-width: 100%;
  /* Ensure the image fits within the circle */
  border-radius: 50px;
  /* Keep the border-radius for the image if desired */
}

.free-shiping {
  color: #9E3040;
  font-size: 18px;
  font-weight: 700;
}

.hero-title {
  font-size: 55px;
  font-weight: bold;
  color: white;
}

.language-switch {
  font-size: 16px;
  color: #232323;
  text-decoration: underline;
  margin-left: 35%;
}

.content {
  width: 100%;
  height: 100%;
  padding: 65px 50px;
  margin-top: -60px;
  margin-left: -15px;
  background: url("./assets/img/hero-bg1.png") top left / cover no-repeat;
  position: relative;
  z-index: 1;
}

.privacy-policy {
  width: 100%;
  height: 100%;
  background: url("./assets/img/Rectangle\ 20.png") center / cover no-repeat;
}


.bg-section-effortless {
  width: 100%;
  height: 100%;
  background: url("./assets/img/effort-bg.jpeg") center / cover no-repeat;
}

.gift-bg {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  /* background: url("./assets/img/gift-bg.png") center no-repeat; */
  background-size: cover;
  /* Adjusts the background image to cover the entire area */
  background-position: top right;
  /* Positions the image at the top-right corner */
}



.sectiongift-text {
  /* margin-left: 30%; */
  text-align: left;
}

.privacy-policy-simplify {
  width: 100%;
  height: 100%;
  /* background: url("./assets/img/Rectangle\ 53.png") center / cover no-repeat; */
}



.sectionabout-text {
  margin-left: 20%;
  text-align: left;
}

.gift-text {
  /* height: Hug (102px)px; */
  gap: 16px;
  /* opacity: 0px; */

}

.btn-danger-about {
  background: linear-gradient(275.76deg, #F26C7E 44.33%, #FF384A 98.56%);
  width: 160px;
  height: 40px;
  top: 3237px;
  left: 118px;
  gap: 0px;
  opacity: 0px;
  color: #e0e0e0;
}

.btn-danger-contact {
  background: linear-gradient(275.76deg, #F26C7E 44.33%, #FF384A 98.56%);
  width: 160px;
  height: 40px;
  top: 3237px;
  align-items: center;
  justify-content: center;
  gap: 0px;
  opacity: 0px;
  color: #e0e0e0;
}

.intelligent-text {


  font-weight: 400;
  line-height: 27.9px;
  letter-spacing: 0.02em;
  text-align: justified;

}

.smile-tag {
  margin-left: 20%;
  text-align: left !important;
}

.smile-img {
  margin-left: -120px;
}

@media (max-width: 767px) {

  .sectionabout-text,
  .smile-tag {
    margin-top: 10px;
    margin-left: 0%;
    text-align: center;
  }

  .smile-img {
    margin-left: 0px;
    margin-top: 10px;
  }
}

.heading-tag {
  text-align: center;
  color: white;
  font-size: 50px;
  font-weight: bold;
}

.desc-tag {
  text-align: center;
  color: white;
  font-size: 18px;
  text-wrap: wrap;
}

.card {
  height: 100%;
  border-radius: 12px;
}

.choose-title {
  font-size: 50px;
  text-align: left;
  color: white;
  font-weight: bold;
}

.choose-card-titles {
  font-size: 55px;
  font-weight: bold;
}

.choose-sub-title {
  font-size: 24px;
  font-weight: 700;
  color: #5C5C5C;
}

/* Navbar.css */
.navbar {
  padding: 0 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: 2px solid #e0e0e0;
  position: relative;
  z-index: 10;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 100px;
  /* Adjust the size as needed */
}

.navbar-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-left: 12%;
}

.navbar-link {
  margin: 0 15px;
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-icon {
  margin: 0 10px;
  font-size: 18px;
  color: #333;
  text-decoration: none;
  background: white;
  border: 2px solid white;
  padding: 6px 12px;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

a:hover {
  color: black;
}

.navbar-signin {
  background-color: #a61d30;
  color: white;
  border: none;
  padding: 10px 30px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
}

.navbar-signin:hover {
  background-color: #8a1728;
}

.navbar-menu-icon {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 20;
}

.close-menu-icon {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

/* Overlay for when the menu is open */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 5;
}

.overlay.active {
  opacity: 1;
  visibility: visible;
}

/* Media Queries for Responsiveness */
@media (max-width: 1070px) {
  .navbar-center {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    margin-left: 0%;
    background-color: white;
    z-index: 9;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: translateY(-100%);
    opacity: 0;
  }

  .navbar-center.active {
    display: flex;
    /* Display the center navbar links when the menu is open */
    transform: translateY(0);
    opacity: 1;
  }

  .close-menu-icon {
    display: block;
    /* Show the close icon when the menu is open */
  }

  .navbar-menu-icon {
    display: block;
    /* Show the menu icon on smaller screens */
  }

  .navbar-right {
    display: flex;
    align-items: center;
  }

  .navbar-icon,
  .navbar-signin {
    display: none;
  }

  .navbar-center .navbar-link {
    margin: 20px 0;
    font-size: 24px;
  }
}


@media (max-width: 991px) {
  .hero-section {
    /* text-align: center; */
    padding: 2rem 1rem;
  }

  .gifting-img {
    margin-left: 0px !important;
  }
}

@media (max-width: 575px) {
  .feature-item {
    margin-bottom: 1rem;
  }

  .feature-heading {
    font-size: 1.2rem;
  }

  .feature-paragraph {
    font-size: 0.9rem;
  }
}

.testi-card {
  height: 420px;
  /* width: 400px; */
}

@media (max-width: 990px) {

  .row-card .col-md-6 {
    display: block;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .phone-image-container {
    display: none;
    flex-direction: column;
  }
}

.padding-comman {
  padding: 0 30px;
}

@media (max-width: 990px) {
  .padding-comman {
    padding: 0 10px;
  }
}

.modal-bg-img {
  width: 100%;
  height: 100vh;
  transition: background-image 0.3s ease-in-out;
  background: url("./assets/img/modal.png") center center / contain no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media (max-width: 768px) {
  .modal-bg-img {
    background-size: cover;
  }

  .modal-dialog {
    max-width: 95%;
  }

  .discount-text {
    font-size: 48px;
  }

  .title-modal {
    font-size: 28px;
  }

  .subtext {
    font-size: 16px;
  }

  .modal-btn {
    font-size: 14px;
  }
}

.modal-dialog {
  max-width: 80%;
  margin: auto;
}

.modal-content {
  background-color: transparent;
  position: relative;
  padding-top: 20px;
  border: none;
}

/* .modal-backdrop.show {
  display: none;
} */

.gifting-img {
  margin-left: 40px;
}

.modal-main-body {
  text-align: center;
}

.modal-main-body img {
  margin-top: -90px;
}

.title-modal {
  font-weight: bold;
  font-size: 33px;
  background: linear-gradient(260deg, #F26C7E 25%, #9E3040 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: -10px;
}

.discount-text {
  font-weight: bold;
  font-size: 60px;
  color: #9E3040;
  margin: 0px 0;
}

.subtext {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 5px;
  margin-top: -10px;
}

.modal-main-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.modal-btn {
  padding: 10px 20px;
  background: linear-gradient(90deg, #F26C7E 0%, #9E3040 100%);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 5px;
  width: 100%;
  text-align: center;
}

/* About us page */
.bg-aboutus {
  width: 100%;
  height: 100%;
  background: url("./assets/svg/aboutus\ \(2\).svg"), center / cover no-repeat;
}

.aboutus-card {
  background-color: transparent;
  border: none;
  width: 100%;
  height: 450px;
}

.about-content {
  position: relative;
  z-index: 2;
  color: #fff;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.about-heading {
  color: white;
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.about-paragraph {
  color: #C2C2C2;
  font-size: 18px;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.button-container {
  display: flex;
  justify-content: left;
  gap: 20px;
  /* padding: 20px 30px; */
}

.buy-btn {
  font-size: 16px;
  border-radius: 8px;
  padding: 10px 25px;
  background-color: white;
  color: black;
}

.about-team {
  background-color: #091242;
  color: white;
  padding: 10px 30px;
  border-radius: 6px;
}

.about-div {
  width: 540px;
}

@media (max-width: 1250px) {
  .about-div {
    width: 100%;
  }

  .about-img-flat {
    margin-left: 0px;
  }
}

.about-img-flat {
  margin-left: 40px;
}

@media (max-width: 1500px) {
  .about-img-flat {
    margin-left: 0px;
  }
}

/* Section2.css */
.section2-container {
  padding: 3rem 2rem;
}

/* Card styles */
.section2-card {
  height: 100%;
  margin-bottom: 3rem;
  border: none;
}

@media (max-width: 1200px) {
  .section2-card {
    text-align: left;
    height: 100%;
  }
}

/* Row styles */
.section2-row {
  display: flex;
  flex-direction: row;
  gap: 0;
}

/* Text column styles */
.section2-text-column {
  display: flex;
  align-items: center;
  padding: 20px;
}

/* Image column styles */
.section2-image-column {
  padding: 20px;
}

/* Title styles */
.section2-title {
  font-size: 19px;
}

/* Heading styles */
.section2-heading {
  font-size: 40px;
  font-weight: bold;
}

/* Description text styles */
.section2-description {
  margin-top: 20px;
}

/* Divider styles */
.section2-divider {
  margin-top: 40px;
  width: 100%;
  /* margin-left: 35px; */
}

/* Details text styles */
.section2-details {
  margin-top: 2rem;
}

/* Main image styles */
.section2-main-image {
  width: 100%;
}

@media (max-width: 990px) {
  .section2-overview-image {
    width: 100%;
  }
}

/* Media query for screens 990px and below */
@media (max-width: 1300px) {
  .section2-row {
    flex-direction: column-reverse;
  }

  .section2-text-column,
  .section2-image-column {
    width: 100%;
    padding: 0;
    text-align: center;
  }

  .section2-text-column {
    order: 1;
    padding-top: 20px;
  }

  .section2-image-column {
    order: 2;
  }

}

.about-icon {
  width: 100px;
}

/* Section3.css */

/* Row style */
.section3-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1.5rem;
  /* Adjust gap between the cards */
}

/* Column style */
.section3-col {
  flex: 1;
  display: flex;
  justify-content: center;
}

/* Icon style */
.section3-icon {
  width: 50px;
  margin-bottom: 1rem;
}

.section3-title {
  font-size: 1.2rem;
  color: white;
}

@media (max-width: 768px) {
  .section3-row {
    flex-direction: column;
    gap: 2rem;
  }
}

.section3-card {
  background-color: transparent;
  height: 100%;
  padding: 20px 0 40px 0;
  width: 200px;
  color: white;
  text-align: center;
  align-items: center;
  border: 2px solid white;
}

.section3-title {
  font-weight: 600;
}

.section3-background {
  width: 100%;
  height: 100%;
  background: url("./assets/svg/aboutus\ \(1\).svg"), center / cover no-repeat;
  padding: 5rem 0;
}


.faq-main {
  background: transparent;
  width: 100%;
}

.faq-heading {
  display: flex;
}

.faq-heading p {
  color: #333;
  font-size: 28px;
  /* font-family: "Raleway-Bold"; */
  font-weight: 900;
}

.left-side-faq,
.right-side-faq {
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.left-faq-row,
.right-faq-row {
  transition: all 0.5s ease-in-out;
  padding: 12px;
}

.faq-qs {
  border-left: 4px solid lightgray;
  color: #333;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-left: 20px;
  cursor: pointer;
  transition: color 0.5s ease-in-out;
}

.faq-ans {
  max-height: 0;
  opacity: 0;
  margin-top: 10px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  color: #333;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 15.29px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.483px;
}

@media (max-width: 900px) {
  .faq-row {
    flex-direction: column-reverse;
    text-align: left;
  }

  .faq-icon {
    margin-bottom: 20px;
    margin-top: -30px;
  }

  .left-side-faq,
  .right-side-faq {
    width: 100%;
  }

  .left-side-faq {
    order: 1;
  }

  .right-side-faq {
    order: 2;
  }
}

/* @media (max-width: 767px) {

  .right-faq-row,
  .left-faq-row {
    flex-wrap: nowrap;
    overflow-x: hidden;
  }

  .faq-divider {
    transition: all 0.5s ease-in-out 0s;
    background: rgba(51, 51, 51, 0.5);
    height: 2px;
  }
} */

.active-faq-heading {
  color: #AA205E;
  border-left-color: #AA205E;
}

.toggle-icon,
.faq-qs-ans-col {
  padding: 4px 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out 0s;
}

.faq-qs-ans-col p {
  margin-top: 10px;
  font-size: 18px;
  /* margin-bottom: -1px; */
  transition: color 0.5s ease-in-out;
}

.faq-plus-icon,
.faq-negative-icon {
  transition: all 0.5s ease-in-out;
}

.faq-negative-icon {
  transform: rotate(180deg);
}

.faq-divider {
  transition: all 0.5s ease-in-out 0s;
  background: rgba(51, 51, 51, 0.5);
}


.work-card {
  position: relative;
  width: auto;
  height: 100vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.work-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.work-card:hover .work-card-overlay {
  opacity: 1;
}

.work-bg1 {
  background-image: url("./assets/img/work-1.png");
  background-size: contain;
}

.work-bg2 {
  background-image: url("./assets/img/work-2.png");
  background-size: contain;
}

.work-bg3 {
  background-image: url("./assets/img/work-3.png");
  background-size: contain;
}

@media (max-width: 990px) {
  .work-card-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.team-container {
  text-align: center;
}

.team-container h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.team-card {
  padding: 10px;
  position: relative;
}

.team-card .card {
  background: linear-gradient(180deg, #A62C58, #432C58);
  color: white;
  border-radius: 10px;
  overflow: hidden;
}

.team-card .card img {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.team-card .card-body {
  background: linear-gradient(180deg, #A62C58, #432C58);
  padding: 15px;
  height: 25px;
  position: relative;
}

.team-card .card-body h5 {
  /* margin-top: 10px; */
  font-size: 18px;
  /* font-weight: bold; */
}

.team-card .card-body p {
  margin-bottom: 0;
  font-size: 14px;
}

.slick-dots li button:before {
  color: #333;
}

/* Contact */
.contact-page {
  min-height: 100%;
  background-color: #D23B3B;
  padding: 20px;
  overflow: hidden;
}

.contact-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
}

.contact-head {
  /* padding: 80px 20px 20px 20px; */
  text-align: center;
  margin-left: 70px;
  margin-bottom: -90px;
}

.contact-form {
  background-color: white;
  padding: 50px 20px 50px 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 650px;
  margin-bottom: 30px;
  margin-left: -100px;
}

.contact-form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-form-group input {
  width: 100%;
}

.top-input input {
  width: 300px;
}

.contact-input,
.contact-textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #D9D9D9;
}

.contact-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-image img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

@media (max-width: 1500px) {
  .contact-form {
    width: 100%;
    margin-left: 0px;
  }

  .contact-head {
    margin-left: 0px;
    margin-bottom: 0px;
  }

  .contact-main {
    margin-top: 2% !important;
  }
}

.contact-form-group label,
.contact-label {
  font-weight: bold;
}

@media (max-width: 990px) {
  .top-input input {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .contact-main {
    flex-direction: row;
  }

  .contact-form-group {
    flex-direction: row;
    justify-content: space-between;
  }

  .contact-head {
    text-align: left;
  }

  .contact-form {
    margin-right: 50px;
    margin-bottom: 0;
  }
}

.contact-btn {
  justify-content: end;
  display: flex;
}

.contact-btn button {
  padding: 8px 35px;
  border: none;
  border-radius: 6px;
  background-color: #E4897B;
}

.flashy-text {
  font-weight: bold;
  color: #a61d30; /* Bright color for emphasis */
  animation: flash 1.5s infinite, moveText 5s linear infinite;
}

@keyframes flash {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}