.footer-container {
  background-color: white;
  padding: 60px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  flex-wrap: wrap;
}

.logo-section {
  display: flex;
  flex-direction: column; /* Stack logo and powered-by text vertically */
  align-items: center; /* Center align both logo and text */
  flex: 1;
}

.logo {
  max-width: 150px;
  height: auto;
  margin-bottom: 10px; /* Add spacing between logo and powered-by text */
}

.powered-by-section {
  text-align: center;
  margin-top: 10px; /* Add some margin above the "Powered by" text */
}

.powered-by-text {
  font-size: 14px;
  color: #000;
}

.link-sections {
  display: flex;
  flex: 3;
  justify-content: space-around;
  color: #000;
  align-items: flex-start;
}

.link-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.section-title {
  font-weight: 800;
  margin-bottom: 10px;
  font-size: 26px;
}

.link-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.link-item {
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 0;
  font-weight: 500;
}

@media (min-width: 768px) {
  .footer-container {
      flex-direction: row;
      padding: 60px 120px; /* Increase padding for larger screens */
  }

  .logo-section {
      align-items: flex-start;
      text-align: left; /* Align logo to the left */
      margin-right: 40px; /* Add more space between logo and links */
  }

  .link-sections {
      justify-content: space-between; /* Distribute sections evenly */
  }

  .link-section {
      align-items: flex-start;
      text-align: left; /* Align links to the left */
  }
}


.footer-link {
  text-decoration: none;
  color: inherit;
}

.footer-link:hover {
  text-decoration: underline;
}
